import { IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { LoyaltyCard, retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetCustomerLoyaltyCardsInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';

/**
 * Creates the input required to make the retail api call
 */
const createCustomerLoyaltyCardsInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    if (
        inputData.requestContext &&
        inputData.requestContext.user &&
        inputData.requestContext.user.isAuthenticated
    ) {
        return createGetCustomerLoyaltyCardsInput({}, null);
    }

    throw new Error('Unable to create order history input.  User is not authenticated.');
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-customer-loyalty-cards',
    action: <IAction<LoyaltyCard[]>>retailAction,
    input: createCustomerLoyaltyCardsInput
});
