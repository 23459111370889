import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext
} from '@msdyn365-commerce/core';
import { convertToString, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { getCurrentAsync, getOrderHistoryAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnersDataActions.g';
import { OrderShipments } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';

/**
 *  Input class for the GetOrganizationWideOrderHistory data action.
 */
export class GetOrganizationWideOrderHistoryInput implements IActionInput {
    public queryResultSettings: IQueryResultSettings;

    constructor(queryResultSettings: IQueryResultSettings) {
        this.queryResultSettings = queryResultSettings;
    }

    public getCacheKey = () => `GetOrganizationWideOrderHistoryInput|${convertToString(this.queryResultSettings)}`;
    public getCacheObjectType = () => 'GetOrganizationWideOrderHistoryInput';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the GetOrganizationWideOrderHistory method.
 * @param {ICreateActionContext} inputData The input data passed to the createInput method.
 */
export const getOrganizationWideOrderHistoryInput = (
    inputData: ICreateActionContext): GetOrganizationWideOrderHistoryInput => {

    if (!inputData?.requestContext?.user?.isAuthenticated) {
        throw new Error('Unable to get order history. User is not authenticated.');
    }

    const queryResultSettings: IQueryResultSettings = QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings;
    return new GetOrganizationWideOrderHistoryInput(queryResultSettings);
};

/**
 * The action method for the GetOrganizationWideOrderHistory data action.
 * @param {GetOrganizationWideOrderHistory} input The action input.
 * @param {IActionContext} ctx The action context.
 */
export async function getOrganizationWideOrderHistory(input: GetOrganizationWideOrderHistoryInput, context: IActionContext): Promise<OrderShipments[]> {
    if (!context?.requestContext?.user?.isAuthenticated) {
        throw new Error('Unable to get order history. User is not authenticated.');
    }

    const businessPartner = await getCurrentAsync({ callerContext: context });
    if (!businessPartner) {
        throw new Error('Unable to get order history. Failed to retrieve business partner id.');
    }

    return getOrderHistoryAsync({ callerContext: context, queryResultSettings: input.queryResultSettings }, businessPartner.BusinessPartnerId);
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-organization-wide-order-history',
    action: <IAction<OrderShipments[]>>getOrganizationWideOrderHistory,
    input: getOrganizationWideOrderHistoryInput
});
