import { PromiseBodyWrapper } from './base-promise-queue';
import { PromiseQueue } from './promise-queue';

export enum FinitePromiseQueueError {
    InvalidMaxQueueLengthPassed = 'Invalid maxQueueLength value passed to FinitePromiseQueue. maxQueueLength should be more or equal to 2.',
    ProcessWasDiscardedFromTheQueue = 'The process was discarded from FinitePromiseQueue.'
}

/**
 * Represents a FIFO queue over promises with a limited number of elements waiting for execution.
 * @remark
 * In case the queue reaches the limit,
 * before adding a new element the queue discards the oldest added element which is waiting to be processed.
 * Does not discard elements which are in progress under execution.
 * The discarded element will not be processed and executed.
 * @author Bohdan Yevchenko <boyevche>
 */
export class FinitePromiseQueue<PromiseResultType> extends PromiseQueue<PromiseResultType> {
    /**
     * @see constructor
     */
    private readonly _maxQueueLength: number;

    /**
     * Initializes the queue with the given limit.
     * @param {number} maxQueueLength
     * Defines the limit of maximum number of elements in the queue.
     * @remarks Includes both the number of elements waiting for the execution
     * and the element processed by the queue at the moment (in case there is some).
     * Value can't be less than 2.
     */
    public constructor(maxQueueLength: number) {
        if (maxQueueLength < 2) {
            throw new Error(FinitePromiseQueueError.InvalidMaxQueueLengthPassed);
        }

        super();
        this._maxQueueLength = maxQueueLength;
    }

    /**
     * Adds promise to the queue and automatically starts the queue execution.
     * @remarks In case the queue has reached the limit, also discards the oldest added element.
     * @param {PromiseBodyWrapper<PromiseResultType>} promiseBody
     * The body of a function which contains a call to the promise which has to be executed in the queue.
     */
    public async enqueue(promiseBody: PromiseBodyWrapper<PromiseResultType>): Promise<PromiseResultType> {
        let totalElementsCount = this._queue.length;

        // If queue hasn't finished processing an element,
        // consider this element as pending.
        if (this._isBusy) {
            ++totalElementsCount;
        }

        // Discards the oldest added element from the queue to meet the given limitations.
        // The very first element in the queue is considered as oldest added.
        // Can't discard the element which is under execution as the promise can't be cancelled.
        if (totalElementsCount === this._maxQueueLength) {
            const element = this._queue.shift();
            if (element) {
                element.reject(FinitePromiseQueueError.ProcessWasDiscardedFromTheQueue);
            }
        }

        return super.enqueue(promiseBody);
    }
}