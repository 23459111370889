import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { getCurrentAsync, getUsersAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnersDataActions.g';

/**
 * Input class for get customer data action
 */
export class OrganizationUsersCountInput implements IActionInput {
    public getCacheKey = () => 'OrganizationUsersCount';
    public getCacheObjectType = () => 'BusinessPartner';
    public dataCacheType = (): CacheType => 'none';
}

export const createGetOrganizationUsersCountInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    return new OrganizationUsersCountInput();
};

export async function getOrganizationUsersCount(input: OrganizationUsersCountInput, ctx: IActionContext): Promise<Number> {
    return getCurrentAsync({ callerContext: ctx })
        .then(businessPartner => {
            if (!businessPartner) {
                return 0;
            }

            return getUsersAsync({ callerContext: ctx }, businessPartner.BusinessPartnerId)
                .then((users) => {
                    return users.length;
                })
                .catch(error => {
                    ctx.telemetry.exception(error);
                    ctx.trace('[get-organization-users-count] Unable to load users');
                    throw new Error('[get-organization-users-count] Unable to load users');
                });
        })
        .catch(error => {
            ctx.telemetry.exception(error);
            ctx.telemetry.debug('[get-organization-users-count] Unable to get BusinessPartner');
            throw new Error('[get-organization-users-count] Unable to get BusinessPartner');
        });
}
export default createObservableDataAction({
    id: 'get-organization-users-count',
    action: <IAction<Number>><unknown>getOrganizationUsersCount,
    input: createGetOrganizationUsersCountInput
});
