import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { convertToString, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { getProductListLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductListsDataActions.g';
import { FullProductInput, getFullProducts, getProductDetailsCriteriaFromActionInput, QueryResultSettingsProxy } from '..';
import { IOrderTemplateLinesState, OrderTemplateLine } from '../utilities/order-templates';
/**
 *  Input class for the GetOrderTemplates data action
 */
export class GetPaginatedOrderTemplateLinesInput implements IActionInput {
    public orderTemplateId: string;
    public queryResultSettings: IQueryResultSettings;
    public searchText: string;

    constructor(orderTemplateId: string, queryResultSettings: IQueryResultSettings, searchText?: string) {
        this.orderTemplateId = orderTemplateId;
        this.queryResultSettings = queryResultSettings;
        this.searchText = searchText || '';
    }

    public getCacheKey = () => `GetPaginatedOrderTemplateLines|${this.orderTemplateId}|${convertToString(this.queryResultSettings)}|${this.searchText}`;
    public getCacheObjectType = () => 'GetPaginatedOrderTemplateLines';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the GetOrderTemplate method
 * @param inputData The input data passed to the createInput method.
 */
export const createGetPaginatedOrderTemplateLinesInput = (
    inputData: ICreateActionContext, orderTemplateId: string, searchText?: string): GetPaginatedOrderTemplateLinesInput => {

    const queryResultSettings: IQueryResultSettings = QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings;
    queryResultSettings.count = true;
    return new GetPaginatedOrderTemplateLinesInput(orderTemplateId, queryResultSettings, searchText);
};

/**
 * The action method for the GetPaginatedOrderTemplateLines data action
 * @param input The action input
 * @param context The action context
 */
export async function getPaginatedOrderTemplateLinesAction(input: GetPaginatedOrderTemplateLinesInput, context: IActionContext): Promise<IOrderTemplateLinesState> {
    if (!context.requestContext.user.isAuthenticated) {
        throw new Error('Unable to get full order templates. User is not authenticated.');
    }

    const orderTemplateLinesState: IOrderTemplateLinesState = {
        lines: [],
        totalCount: 0
    };
    const promise = getProductListLinesAsync({
        callerContext: context, queryResultSettings: input.queryResultSettings
    }, input.orderTemplateId, input.searchText); // tslint:disable-line:align
    const productListLines = await promise;
    const productInputs: FullProductInput[] = [];
    if (productListLines && productListLines.length > 0) {
        orderTemplateLinesState.totalCount = promise.metadata.count || orderTemplateLinesState.lines.length;
        productListLines.forEach((listLine) => {
            if (listLine.ProductId) {
                orderTemplateLinesState.lines.push(new OrderTemplateLine(listLine));
                productInputs.push(new FullProductInput(listLine.ProductId, context.requestContext.apiSettings, getProductDetailsCriteriaFromActionInput(context)));
            }
        });
        if (productInputs.length > 0) {
            const fullProductsInfo = await getFullProducts(productInputs, context)
                .then((products: FullProduct[]) => {
                    if (products) {
                        return products;
                    } else {
                        return <FullProduct[]>[];
                    }
                })
                .catch(error => {
                    context.trace(error);
                    context.telemetry.error(error.message);
                    context.telemetry.error('[getPaginatedOrderTemplateLinesAction] Unable to retrieve product info.');
                });
            if (fullProductsInfo) {
                orderTemplateLinesState.lines.forEach(line => {
                    const fullProduct = fullProductsInfo.find(product => product.ProductDetails.RecordId === line.productListLine.ProductId);
                    if (!fullProduct) {
                        context.telemetry.error('[getPaginatedOrderTemplateLinesAction] Unable to find product info for an order template line.');
                        return;
                    }
                    line.fullProduct = fullProduct;
                });
            }
            return orderTemplateLinesState;
        }
        context.trace(orderTemplateLinesState.lines.length.toString());
        return orderTemplateLinesState;
    }
    return orderTemplateLinesState;
}

/**
 * The GetPaginatedOrderTemplateLines Data Action
 * Returns order templates
 */
export default createObservableDataAction<IOrderTemplateLinesState>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/get-paginated-order-template-lines',
    action: <IAction<IOrderTemplateLinesState>>getPaginatedOrderTemplateLinesAction,
    input: createGetPaginatedOrderTemplateLinesInput
});