import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { ProductList, ProductListLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * OrderTemplateLine state information
 */
export class OrderTemplateLine {
    public productListLine: ProductListLine;
    public fullProduct?: FullProduct;

    constructor(productListLine: ProductListLine, fullProduct?: FullProduct) {
        this.productListLine = productListLine;
        this.fullProduct = fullProduct;
    }
}

export interface IOrderTemplateLinesState {
    lines: OrderTemplateLine[];
    totalCount: number;
}

/**
 * Order template class
 */
export class OrderTemplate {
    public productList: ProductList;
    public orderTemplateLines: OrderTemplateLine[];
    public totalLines: number;

    constructor(productList: ProductList, orderTemplateLines: OrderTemplateLine[], totalLines: number) {
        this.productList = productList;
        this.orderTemplateLines = orderTemplateLines;
        this.totalLines = totalLines;
    }
}