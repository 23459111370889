import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICommerceApiSettings, ICreateActionContext, IDictionary, IGeneric } from '@msdyn365-commerce/core';
import { getByIdsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';
import { buildCacheKey, generateProductImageUrl } from './utilities/utils';

/**
 * Product Input
 */
export class ProductInput implements IActionInput {
    public productId: number;
    public channelId: number;
    private apiSettings: ICommerceApiSettings;

    constructor(productId: number | string, apiSettings: ICommerceApiSettings, channelId?: number) {
        this.apiSettings = apiSettings;
        this.productId = +productId;
        this.channelId = channelId || apiSettings.channelId;
    }

    public getCacheKey = () =>  buildCacheKey(`RecordId-${this.productId.toString()}-ChannelId-${this.channelId.toString()}`, this.apiSettings);
    public getCacheObjectType = () => 'SimpleProduct';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Creates the input required to make the retail api call
 */
export const createSimpleProductsInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput[] => {
    let productIds = inputData.config && inputData.config.productIds;
    if (!productIds) {
        return [];
    }

    productIds = typeof productIds === 'string' ? productIds.split(',') : productIds;
    return !Array.isArray(productIds)
        ? []
        : productIds.map((productId: string) => {
              return new ProductInput(productId, inputData.requestContext.apiSettings);
          });
};

/**
 * Calls the Retail API and returns the product based on the passed ProductInput
 */
export async function getSimpleProductsAction(inputs: ProductInput[], ctx: IActionContext): Promise<SimpleProduct[]> {
    if (!Array.isArray(inputs) || inputs.length === 0) {
        ctx.trace('[getSimpleProductsAction] Invalid or empty inputs passed.');
        return [];
    }

    const productIdMapping: IDictionary<number> = {};
    const productIds = inputs.map((input, idx) => {
        productIdMapping[input.productId] = idx;
        return input.productId;
    });

    return getByIdsAsync({ callerContext: ctx, queryResultSettings: QueryResultSettingsProxy.getDefault().QueryResultSettings }, inputs[0].channelId, productIds).then(products => {
        const mappedProducts = products.map(product => {
                try {
                    const newImageUrl = generateProductImageUrl(product, ctx.requestContext.apiSettings);

                    if (newImageUrl) {
                        product.PrimaryImageUrl = newImageUrl;
                    }

                    return product;
                } catch (e) {
                    ctx.trace('[getSimpleProductsAction] Unable to update ImageURL for Product');
                }
            })
            .reduce((memo: SimpleProduct[], product: SimpleProduct | undefined) => {
                if (!product) {
                    return memo;
                }
                const idx = productIdMapping[product.RecordId];
                memo[idx] = product;
                return memo;
            }, []);

        // NOTE: <ZAFINE> This is not an ideal fix, but will resolve all current issues
        return inputs.map(input => {
            const foundProduct = mappedProducts.find(product => product && product.RecordId === input.productId);
            return foundProduct || <SimpleProduct>{};
        });
    });
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-simple-products',
    action: <IAction<SimpleProduct[]>>getSimpleProductsAction,
    input: createSimpleProductsInput,
    isBatched: true
});
