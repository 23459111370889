import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { QueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { searchAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductListsDataActions.g';
import { ProductList, ProductListSearchCriteria } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 *  Input class for the GetOrderTemplates data action
 */
export class GetOrderTemplatesInput implements IActionInput {
    public readonly productListId: string;
    public readonly queryResultSettings?: QueryResultSettings;

    constructor(productListId?: string, queryResultSettings?: QueryResultSettings) {
        this.productListId = productListId || '';
        this.queryResultSettings = queryResultSettings;
    }

    public getCacheKey = (): string => `${this.productListId}|${this.queryResultSettings?.Paging?.Top}|${this.queryResultSettings?.Paging?.Skip}`;
    public getCacheObjectType = () => 'ProductList';
    public dataCacheType = (): CacheType => 'instance';
    public shouldCacheOutput = () => false;
}

/**
 * createInput method for the GetOrderTemplate method
 * @param inputData The input data passed to the createInput method.
 * @param productListId? The product list identifier.
 * @param queryResultSettings? The query result setting required for pagination/ordering.
 */
export const createGetOrderTemplatesInput = (inputData: ICreateActionContext, productListId?: string, queryResultSettings?: QueryResultSettings): GetOrderTemplatesInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create get order template. User is not authenticated.');
    }

    return new GetOrderTemplatesInput(productListId || '', queryResultSettings);
};

/**
 * The action method for the GetOrderTemplates data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getOrderTemplatesAction(input: GetOrderTemplatesInput, ctx: IActionContext): Promise<ProductList[]> {
    try {

        const productListSearchCriteria: ProductListSearchCriteria = {
            ProductListId: input.productListId,
            CustomerId: ctx.requestContext.user.customerAccountNumber
        };
        return searchAsync(
            {
                callerContext: ctx,
                queryResultSettings: input.queryResultSettings
            },
            productListSearchCriteria
        );
    } catch (error) {
        ctx.telemetry.error('Not able to get order template(s)', error);
        throw error;
    }
}

/**
 * The GetOrderTemplates Data Action
 * Returns order templates
 */
export default createObservableDataAction<ProductList[]>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/get-order-templates',
    action: <IAction<ProductList[]>>getOrderTemplatesAction,
    input: createGetOrderTemplatesInput
});