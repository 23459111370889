import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import {
    getAttributeDefinitionsAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { AttributeDefinition, AttributeDefinitionCriteria} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { buildCacheKey } from './utilities/utils';

/**
 * Input class for get attribute definitions data action
 */
export class GetAttributeDefinitionsInput implements IActionInput {
    public attributeDefinitionCriteria?: AttributeDefinitionCriteria;
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, attributeDefinitionCriteria?: AttributeDefinitionCriteria) {
        this.attributeDefinitionCriteria = attributeDefinitionCriteria;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey('AttributeDefinitions', this.apiSettings);
    public getCacheObjectType = () => 'CustomerAttribute';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetAttributeDefinitionsInput => {
    const { apiSettings, channel } = inputData.requestContext;
    if (!channel || !channel.CustomerAttributeGroupId) {
        throw new Error('[getAttributeDefinitions][createInput]: Customer attribute group id is not found.');
    }

    return new GetAttributeDefinitionsInput(apiSettings, {AttributeGroupIdentifier: channel.CustomerAttributeGroupId});
};

const getAttributeDefinitionsAction = async (input: GetAttributeDefinitionsInput, ctx: IActionContext): Promise<AttributeDefinition[]> => {
    if (input && ctx.requestContext.channel && ctx.requestContext.channel.CustomerAttributeGroupId) {
        return getAttributeDefinitionsAsync({ callerContext: ctx }, input.attributeDefinitionCriteria || null).then(response => {
            return  response;
        });
    }

    ctx.trace('[getAttributeDefinitions]Invalid input passed to action');
    return [];
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-custom-attributes',
    action: <IAction<AttributeDefinition[]>>getAttributeDefinitionsAction,
    input: createInput
});
