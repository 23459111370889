import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { getCartState, ICartState } from '@msdyn365-commerce/global-state';
import { LoyaltyCard } from '@msdyn365-commerce/retail-proxy';
import { issueLoyaltyCardAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { getLoyaltyAction, GetLoyaltyCardInput} from './get-loyalty-card';
import { buildCacheKey } from './index';

/**
 *  Input class for the issueLoyalty data action
 */
export class IssueLoyaltyInput implements IActionInput {
    public userAccountNumber?: string;
    public apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, userAccountNumber?: string) {
        this.userAccountNumber = userAccountNumber;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`IssueLoyalty-${this.userAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'GetIssueLoyalty';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the issueLoyalty method
 * @param inputData The input data passed to the createInput method
 */
export const createIssueLoyaltyInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create issue loyalty input.  User is not authenticated.');
    }

    return new IssueLoyaltyInput(inputData.requestContext.apiSettings);
};

/**
 * The action method for the issueLoyalty data action
 * @param input The action input
 * @param ctx The action context
 */
export async function issueLoyaltyAction(input: IssueLoyaltyInput, ctx: IActionContext): Promise<LoyaltyCard> {
    const promises: [Promise<ICartState>,Promise<LoyaltyCard>] = [ getCartState(ctx), _getLoyalty(input, ctx)];
    return Promise.all(promises)
            .then((result) => {
                const cartState = result[0];
                const card = result[1];
                if (card && card.CardNumber) {
                    updateCart(cartState, card);
                    return card;
                }
                //@ts-ignore
                //TO-DO: Remove after SDK bug fix https://msdyneng.visualstudio.com/FinOps/_workitems/edit/473891
                return issueLoyaltyCardAsync({ callerContext: ctx }, { CustomerAccount: input.userAccountNumber || null })     
                    .then((issuedCard: LoyaltyCard) => {
                        updateCart(cartState, issuedCard);
                        return issuedCard;
                    })
                    //@ts-ignore
                    .catch(error => {
                        ctx.telemetry.exception(error);
                        ctx.telemetry.debug('Issuing loyalty card failed');
                        throw new Error('Issuing loyalty card failed');
                    });
            })
            .catch((error: Error) => {
                ctx.telemetry.exception(error);
                ctx.telemetry.debug('Unable to issue loyalty card');
                throw new Error('Unable to issue loyalty card');
            });
}

async function _getLoyalty(input: IssueLoyaltyInput, ctx: IActionContext): Promise<LoyaltyCard> {
    const loyaltyCardInput = new GetLoyaltyCardInput(input.apiSettings);
    return getLoyaltyAction(loyaltyCardInput, ctx);
}

function updateCart(cartState: ICartState, card: LoyaltyCard): void {
    cartState.updateLoyaltyCardId({loyaltyCardNumber: card.CardNumber});
}

/**
 * The getLoyaltyCard data action
 * Returns the loyalty card belonging to the customer
 */
export default createObservableDataAction<LoyaltyCard>({
    id: '@msdyn365-commerce-modules/retail-actions/issue-loyalty',
    action: <IAction<LoyaltyCard>>issueLoyaltyAction,
    input: <(args: ICreateActionContext) => IActionInput>createIssueLoyaltyInput
});
