import { IAction } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IDataServiceRequest, retailAction } from '@msdyn365-commerce/retail-proxy/dist';
import { createGetOrderShipmentsHistoryInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';
import { OrderShipments } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IDataServiceRequest => {
    if (
        inputData.requestContext &&
        inputData.requestContext.user &&
        inputData.requestContext.user.isAuthenticated
    ) {
        return createGetOrderShipmentsHistoryInput({
            Paging: {
                Top: 100,
                Skip: 0
            }
        },
        //@ts-ignore
        null);
    }

    throw new Error('Unable to create order history input.  User is not authenticated.');
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-order-history',
    action: <IAction<OrderShipments[]>>retailAction,
    input: createInput
});
