import { getPaginatedOrderTemplateLinesAction, GetPaginatedOrderTemplateLinesInput } from './get-paginated-order-template-lines'
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext
} from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { CartLine, QueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { addCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

/**
 *  Input class for the AddOrderTemplateToCart data action.
 */
export class AddOrderTemplateToCartInput implements IActionInput {
    public readonly orderTemplateId: string;

    constructor(orderTemplateId: string) {
        this.orderTemplateId = orderTemplateId;
    }

    public getCacheKey = () => 'AddOrderTemplateToCartInput';
    public getCacheObjectType = () => 'AddOrderTemplateToCartInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the AddOrderTemplateToCart method.
 * @param {ICreateActionContext} inputData The input data passed to the createInput method.
 * @param {string} orderTemplateId The id of template.
 */
export const addOrderTemplateToCartInput = (inputData: ICreateActionContext, orderTemplateId: string): AddOrderTemplateToCartInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create order template. User is not authenticated.');
    }
    return new AddOrderTemplateToCartInput(orderTemplateId);
};

/**
 * The action method for the AddOrderTemplateToCart data action.
 * @param {AddOrderTemplateToCartInput} input The action input.
 * @param {IActionContext} ctx The action context.
 */
export async function addOrderTemplateToCartAction(input: AddOrderTemplateToCartInput, context: IActionContext): Promise<CartLine[]> {
    const cartState = await getCartState(context);
    const chunkCount = 1000;
    const queryResultSettings: QueryResultSettings = {
        Paging: {
            Top: chunkCount,
            Skip: 0
        }
    };
    const getPaginatedOrderTemplateLinesInput = new GetPaginatedOrderTemplateLinesInput(input.orderTemplateId, queryResultSettings);
    let orderTemplateLines = await getPaginatedOrderTemplateLinesAction(getPaginatedOrderTemplateLinesInput, context);
    let addedCartLines: CartLine[] = [];
    for (let skip = chunkCount; orderTemplateLines.lines.length !== 0; skip += chunkCount) {
        const cartLines: CartLine[] = orderTemplateLines.lines.map(productLine => {
            // tslint:disable-next-line:no-unnecessary-local-variable
            const cartLine: CartLine = {
                ItemId: productLine.fullProduct?.ProductDetails.ItemId,
                ProductId: productLine.productListLine.ProductId,
                Quantity: productLine.productListLine.Quantity,
                TrackingId: '',
                UnitOfMeasureSymbol: productLine.productListLine.UnitOfMeasure
            };
            return cartLine;
        });
        await addCartLinesAsync({ callerContext: context }, cartState.cart.Id, cartLines, cartState.cart.Version!).catch(error => {
            context.telemetry.trace(error);
            context.telemetry.trace('Unable to add order template to cart.');
            throw error;
        });
        addedCartLines = addedCartLines.concat(cartLines);
        getPaginatedOrderTemplateLinesInput.queryResultSettings.Paging!.Skip = skip;
        orderTemplateLines = await getPaginatedOrderTemplateLinesAction(getPaginatedOrderTemplateLinesInput, context);
    }
    await cartState.refreshCart({});
    return addedCartLines;
}

/**
 * The AddOrderTemplateToCart Data Action.
 * Returns created order template.
 */
export default createObservableDataAction<CartLine[]>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/add-order-template-to-cart',
    action: <IAction<CartLine[]>>addOrderTemplateToCartAction,
    input: addOrderTemplateToCartInput
});