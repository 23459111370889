import { IAction, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext } from '@msdyn365-commerce/core';
import { Address, Customer } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { createAddressManagementInput, doAddressManagementOperation, IAddressManagementInput } from './add-address';

// TODO: Centralize this logic in a common utility area
export function updateAddressHandler(customer: Customer, address: Address): Customer {
    customer.Addresses = customer.Addresses || [];
    if (address.IsPrimary) {
        customer.Addresses.forEach(cur => cur.IsPrimary = false)
    }
    customer.Addresses = [...customer.Addresses.filter(cur => cur.RecordId !== address.RecordId), address];
    return customer;
}

export async function updateAddressAction(input: IAddressManagementInput, ctx: IActionContext): Promise<Address[]> {
    return doAddressManagementOperation(input, ctx, updateAddressHandler);
}

/**
 * The updateAddress data action
 * Calls the read RetailServer API to get information about a customer
 * Merge the passed address information with the address information from
 * the retireved customer, and then updates that customer via the update RetailServer API.
 */
export default createObservableDataAction<Address[]>({
    id: '@msdyn365-commerce-modules/retail-actions/update-address',
    action: <IAction<Address[]>>updateAddressAction,
    input: <(args: ICreateActionContext) => IActionInput>(<IAny>createAddressManagementInput)
});
