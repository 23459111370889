import { ObjectExtensions } from './object-extensions';

/**
 * Namespace which extends string functionality.
 */
export namespace StringExtensions {
    /**
     * Checks if string is null, undefined, empty, or whitespace.
     * @param {string | null | undefined} value The string to examine.
     * @returns {boolean} True if it's null, undefined, empty, or whitespace; false otherwise.
     */
    export function isNullOrWhitespace(value: string | null | undefined): boolean {
        return isNullOrEmpty(value) || value!.trim() === '';
    }

    /**
     * Checks if string is null, undefined, or empty.
     * @param {string | null | undefined} value The string to examine.
     * @returns {boolean} True if it's null, undefined, empty, or whitespace; false otherwise.
     */
    export function isNullOrEmpty(value: string | null | undefined): boolean {
        if (ObjectExtensions.isNullOrUndefined(value)) {
            return true;
        }

        if (!ObjectExtensions.isString(value)) {
            throw new Error('StringExtensions has received a non-string input.');
        }

        return value === '';
    }
}