import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ChannelDeliveryOptionConfiguration } from '@msdyn365-commerce/retail-proxy';
import { getChannelDeliveryOptionConfigurationAsync  } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { buildCacheKey } from './utilities/utils';

/**
 * Input class for get channel delivery option configuration data action
 */
export class GetChannelDeliveryConfigurationInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, accountNumber?: string) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey('ChannelDeliveryConfiguration', this.apiSettings);
    public getCacheObjectType = () => 'ChannelDeliveryConfiguration';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetChannelDeliveryConfigurationInput => {
    const { apiSettings } = inputData.requestContext;
    if (!apiSettings) {
        throw new Error('[getChannelDeliveryOptionConfiguration][createInput]: API setting not available.');
    }

    return new GetChannelDeliveryConfigurationInput(apiSettings);
};

const getChannelDeliveryOptionConfigurationAction = async (input: GetChannelDeliveryConfigurationInput, ctx: IActionContext): Promise<ChannelDeliveryOptionConfiguration> => {
    if (input) {
        return getChannelDeliveryOptionConfigurationAsync({ callerContext: ctx }).then(response => {
            return response;
        });
    }

    ctx.trace('[get-channel-delivery-option-configuration]Invalid input passed to action');
    return <ChannelDeliveryOptionConfiguration>{};
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-channel-delivery-option-configuration',
    action: <IAction<ChannelDeliveryOptionConfiguration>>getChannelDeliveryOptionConfigurationAction,
    input: createInput
});
