import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { ProductList } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { getOrderTemplates, GetOrderTemplatesInput, QueryResultSettingsProxy } from '..';
import { getPaginatedOrderTemplateLinesAction, GetPaginatedOrderTemplateLinesInput } from '..';
import { OrderTemplate } from '../utilities/order-templates';

/**
 *  Input class for the GetOrderTemplates data action
 */
export class GetFullOrderTemplateInput implements IActionInput {
    public orderTemplateId: string;
    public queryResultSettings: IQueryResultSettings;

    constructor(orderTemplateId: string, queryResultSettings: IQueryResultSettings) {
        this.orderTemplateId = orderTemplateId;
        this.queryResultSettings = queryResultSettings;
    }

    public getCacheKey = () => `GetFullOrderTemplate|${this.orderTemplateId}`;
    public getCacheObjectType = () => 'GetFullOrderTemplate';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the GetOrderTemplate method
 * @param inputData The input data passed to the createInput method.
 */
export const createGetFullOrderTemplateInput = (inputData: ICreateActionContext, orderTemplateId: string): GetFullOrderTemplateInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to get full order templates. User is not authenticated.');
    }
    const queryResultSettings: IQueryResultSettings = QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings;
    queryResultSettings.count = true;

    return new GetFullOrderTemplateInput(orderTemplateId, queryResultSettings);
};

/**
 * The action method for the GetFullOrderTemplate data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getFullOrderTemplateAction(input: GetFullOrderTemplateInput, ctx: IActionContext): Promise<OrderTemplate> {
    if (!ctx.requestContext.user.isAuthenticated) {
        throw new Error('Unable to get full order template. User is not authenticated.');
    }

    const inputParams = new GetOrderTemplatesInput(input.orderTemplateId);
    const productLists: ProductList[] = await getOrderTemplates(inputParams, ctx);

    if (!productLists || productLists.length !== 1) {
        throw new Error('Unable to get full order template. Order template was not found.');
    }

    const inputLines = new GetPaginatedOrderTemplateLinesInput(productLists[0].Id, input.queryResultSettings);
    const linesState = await getPaginatedOrderTemplateLinesAction(inputLines, ctx);
    return new OrderTemplate(productLists[0], linesState.lines, linesState.totalCount);
}

/**
 * The GetFullOrderTemplate Data Action
 * Returns order templates
 */
export default createObservableDataAction<OrderTemplate>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/get-full-order-template',
    action: <IAction<OrderTemplate>>getFullOrderTemplateAction,
    input: createGetFullOrderTemplateInput
});