import { IPromiseQueue, PromiseBodyWrapper, PromiseRejectType, PromiseResolveType } from './base-promise-queue';

/**
 * Represents an inner node which is stored in a promise queue.
 * Used internally in the PromiseQueue implementation.
 * @author Bohdan Yevchenko <boyevche>
 */
class PromiseQueueElement<PromiseResultType> {
    /**
     * The body of a wrapper-function which contains a call to the promise which has to be executed in the queue.
     */
    public readonly body: PromiseBodyWrapper<PromiseResultType>;

    /**
     * Method that resolves the promise after the promise from the body is resolved.
     */
    public readonly resolve: PromiseResolveType<PromiseResultType>;

    /**
     * Method that rejects the promise after the promise from the body is rejected.
     */
    public readonly reject: PromiseRejectType;

    /**
     * Initializes queue element with the given data.
     * @param {PromiseBodyWrapper<PromiseResultType>} body The body of a wrapper-function which contains a call to the promise which has to be executed in the queue.
     * @param {PromiseResolveType<PromiseResultType>} resolve Method that resolves the promise after the promise from the body is resolved.
     * @param {PromiseRejectType} reject Method that rejects the promise after the promise from the body is rejected.
     */
    public constructor(
        body: PromiseBodyWrapper<PromiseResultType>,
        resolve: PromiseResolveType<PromiseResultType>,
        reject: PromiseRejectType) {

        this.body = body;
        this.resolve = resolve;
        this.reject = reject;
    }
}

/**
 * Represents a FIFO basic queue over promises.
 * @author Bohdan Yevchenko <boyevche>
 */
export class PromiseQueue<PromiseResultType> implements IPromiseQueue<PromiseResultType> {
    /**
     * A list of promises waiting for execution.
     */
    protected readonly _queue: PromiseQueueElement<PromiseResultType>[];

    /**
     * Defines whether the queue is processing some element.
     */
    protected _isBusy: boolean;

    /**
     * Defines whether the queue can start processing new element.
     */
    private get _canProcess(): boolean {
        return !this._isBusy && this._queue.length !== 0;
    }

    /**
     * Creates a new instance of PromiseQueue.
     */
    public constructor() {
        this._queue = [];
        this._isBusy = false;
    }

    /**
     * Adds promise to the queue and automatically starts the queue execution.
     * @param {PromiseBodyWrapper<PromiseResultType>} promiseBody
     * The body of a function which contains a call to the promise which has to be executed in the queue.
     */
    public async enqueue(promiseBody: PromiseBodyWrapper<PromiseResultType>): Promise<PromiseResultType> {
        // tslint:disable-next-line:promise-must-complete
        return new Promise<PromiseResultType>(async (resolve, reject) => {
            this._queue.push(new PromiseQueueElement(promiseBody, resolve, reject));
            await this._dequeue();
        });
    }

    /**
     * If the queue is free, starts processing the first element in the queue and waits until all the elements are processed.
     * Otherwise (if busy or has no elements to process), does nothing.
     */
    private async _dequeue(): Promise<void> {
        // Skip if queue is not able to process any elements.
        if (!this._canProcess) {
            return;
        }

        // Lock queue to prevent parallel execution.
        this._isBusy = true;

        // Retrieve an element from the waiting queue and start processing.
        const element: PromiseQueueElement<PromiseResultType> = this._queue.shift()!;
        await this._processElement(element);

        // Continue executing the subsequent queue elements.
        await this._processNext();
    }

    /**
     * Executes the given wrapper over the promise and calls initial resolve/reject correspondingly.
     * @param {PromiseQueueElement<PromiseResultType>} element The queue element which should be processed now.
     */
    private async _processElement(element: PromiseQueueElement<PromiseResultType>): Promise<void> {
        try {
            await element.body().then(element.resolve).catch(element.reject);
        } catch (error) {
            element.reject(error);
        }
    }

    /**
     * Unlocks the queue and tries to process the next element in the queue.
     */
    private async _processNext(): Promise<void> {
        this._isBusy = false;
        await this._dequeue();
    }
}