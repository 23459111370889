import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { BusinessPartnerUser } from '@msdyn365-commerce/retail-proxy';
import { getCurrentAsync, getUsersAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnersDataActions.g';
import { buildCacheKey } from './utilities/utils';

/**
 * Input class for get customer data action
 */
export class OrganizationUsersInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }
    public getCacheKey = () => buildCacheKey('OrganizationUsers', this.apiSettings);
    public getCacheObjectType = () => 'BusinessPartner';
    public dataCacheType = (): CacheType => 'request';
}

export const createGetOrganizationUsersInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    return new OrganizationUsersInput(requestContext.apiSettings);
};

export async function getOrganizationUsers(input: OrganizationUsersInput, ctx: IActionContext): Promise<BusinessPartnerUser[]> {
    return getCurrentAsync({ callerContext: ctx })
        .then(businessPartner => {
            return getUsersAsync({ callerContext: ctx }, businessPartner.BusinessPartnerId)
                .then((users) => {
                    return users;
                })
                .catch(error => {
                    ctx.telemetry.exception(error);
                    ctx.trace('[get-organization-users] Unable to load users');
                    throw new Error('[get-organization-users] Unable to load users');
                });
        })
        .catch(error => {
            ctx.telemetry.exception(error);
            ctx.telemetry.debug('[get-organization-users] Unable to get BusinessPartner');
            throw new Error('[get-organization-users] Unable to get BusinessPartner');
        });
}
export default createObservableDataAction({
    id: 'get-organization-users',
    action: <IAction<BusinessPartnerUser[]>><unknown>getOrganizationUsers,
    input: createGetOrganizationUsersInput
});
