import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { updateProductListLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductListsDataActions.g';
import { ProductListLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import getPaginatedOrderTemplateLines, { GetPaginatedOrderTemplateLinesInput } from './get-paginated-order-template-lines';

/**
 *  Input class for the UpdateOrderTemplate data action.
 */
export class UpdateOrderTemplateInput implements IActionInput {
    public readonly orderTemplateId: string;
    public readonly productId: number;
    public readonly quantity: number;
    public readonly unitOfMeasure: string;

    constructor(orderTemplateId: string, productId: number, quantity: number, unitOfMeasure: string) {
        this.orderTemplateId = orderTemplateId;
        this.productId = productId;
        this.quantity = quantity;
        this.unitOfMeasure = unitOfMeasure || 'ea';
    }

    public getCacheKey = () => 'UpdateOrderTemplateInput';
    public getCacheObjectType = () => 'UpdateOrderTemplateInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the UpdateOrderTemplate method.
 * @param {ICreateActionContext} inputData The input data passed to the createInput method.
 * @param {string} orderTemplateId The id of order template to update.
 * @param {number} productId The id of a product to add.
 * @param {number} quantity How many items of this product to add.
 */
export const updateOrderTemplateInput = (
    inputData: ICreateActionContext, orderTemplateId: string, productId: number, quantity: number, unitOfMeasure: string): UpdateOrderTemplateInput => {

    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create order template. User is not authenticated.');
    }
    return new UpdateOrderTemplateInput(orderTemplateId, productId, quantity, unitOfMeasure);
};

/**
 * The action method for the UpdateOrderTemplate data action.
 * @param {UpdateOrderTemplateInput} input The action input.
 * @param {IActionContext} ctx The action context.
 */
export async function updateOrderTemplateAction(input: UpdateOrderTemplateInput, ctx: IActionContext): Promise<ProductListLine> {
    try {
        if (!ctx.requestContext.user.isAuthenticated) {
            throw Error('User token was not found when updating the order template');
        }
        const getLinesInput = new GetPaginatedOrderTemplateLinesInput(input.orderTemplateId, {}, input.productId.toString());
        const linesState = await getPaginatedOrderTemplateLines(getLinesInput, ctx);
        if (!linesState) {
            throw Error('Order template was not found when updating the order template');
        }

        const linesToUpdate = linesState.lines.filter(line => line.productListLine.ProductId === input.productId);
        if (linesToUpdate.length !== 1) {
            throw Error('Unable to find the line to update when updating the order template');
        }

        const lineToUpdate = linesToUpdate[0].productListLine;

        lineToUpdate.UnitOfMeasure = input.unitOfMeasure;
        lineToUpdate.Quantity = input.quantity;

        const updatedLines: ProductListLine[] = await updateProductListLinesAsync({
            callerContext: ctx
        }, input.orderTemplateId, [lineToUpdate]);

        if (!updatedLines || updatedLines.length !== 1) {
            throw Error('Server returned invalid lines when updating order template');
        }
        const updatedLine = updatedLines.filter(line => line.ProductId === input.productId);
        if (updatedLine.length !== 1 || updatedLine[0].Quantity !== input.quantity || updatedLine[0].UnitOfMeasure !== input.unitOfMeasure) {
            throw Error('Server did not update the line when updating order template');
        }
        return updatedLine[0];
    } catch (error) {
        ctx.telemetry.error('Not able to add an item to order template', error);
        throw error;
    }
}

/**
 * The UpdateOrderTemplate Data Action.
 * Returns product list line that was added to the order template.
 */
export default createObservableDataAction<ProductListLine>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/update-order-template-line',
    action: <IAction<ProductListLine>>updateOrderTemplateAction,
    input: updateOrderTemplateInput
});