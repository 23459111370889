/**
 * Namespace which extends Object functionality.
 */
export namespace ObjectExtensions {
    // tslint:disable:no-any

    /**
     * @description Verifies whether the value is null or undefined.
     * @param {any} value The value to examine.
     * @returns {boolean} True if the value is null or undefined, false otherwise.
     */
    export function isNullOrUndefined(value: any): value is null | undefined  {
        return value === null || value === undefined;
    }

    /**
     * @description Verifies whether the value is of type string.
     * @param {any} value The value to examine.
     * @returns {boolean} True if the value is a string, false otherwise.
     */
    export function isString(value: any): value is string {
        return typeof value === 'string';
    }

    /**
     * @description Verifies whether the value is of type number.
     * @param {any} value The value to examine.
     * @returns {boolean} True if the value is a number, false otherwise.
     */
    export function isNumber(value: any): value is number {
        return typeof value === 'number';
    }

    /**
     * Verifies whether the object is a function.
     * @param {any} object The object.
     * @return {boolean} True if the object is a function, false otherwise.
     */
    export function isFunction(value: any): boolean {
        return typeof value === 'function';
    }

    // tslint:enable:no-any
}