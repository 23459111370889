import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { BusinessPartner, BusinessPartnerUser } from '@msdyn365-commerce/retail-proxy';
import { getCurrentAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnersDataActions.g';
import { createBusinessPartnerUserAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnerUsersDataActions.g';
/**
 * Input class for addOrganizationUser data action
 */
export class AddOrganizationUserInput implements IActionInput {
    public user: BusinessPartnerUser;

    constructor(newUser: BusinessPartnerUser) {
        this.user = newUser;
    }

    public getCacheKey = () => `AddOrganizationUser`;
    public getCacheObjectType = () => 'AddOrganizationUser';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Input class for addOrganizationUser data action
 */
export const createAddOrganizationUserInput = (inputData: ICreateActionContext<IGeneric<IAny>>, newUser: BusinessPartnerUser): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    return new AddOrganizationUserInput(newUser);
};

/**
 * The addOrganizationUser data action
 * Calls getCurrent() to get a businessPartnerId from the user requesting the action.
 * It then maps the newUsers' businessPartnerId to the same as the above user.
 * Calls the createBusinessPartner to then create a new entitiy in the database with the
 * information passed into through newUser
 */
export async function addOrganizationUser(input: AddOrganizationUserInput, ctx: IActionContext): Promise<BusinessPartnerUser> {
    const { user } = input;

    return getCurrentAsync({ callerContext: ctx })
        .then((businessPartner: BusinessPartner) => {
            // set the new accounts businessPartnerId from the admin account
            user.BusinessPartnerId = businessPartner.BusinessPartnerId;

            return createBusinessPartnerUserAsync({ callerContext: ctx}, user)
                .then(newUser => {
                    return newUser;
                })
                .catch(() => {
                    ctx.telemetry.debug('[add-organization-user] Unable to create BusinessPartnerUser account');
                    throw new Error('[add-organization-user] Unable to create BusinessPartnerUser account');
                });
        })
        .catch(error => {
            ctx.telemetry.exception(error);
            ctx.telemetry.debug('[add-organization-user] Unable to get BusinessPartner Admin account');
            throw new Error('[add-organization-user] Unable to get BusinessPartner Admin account');
        });
}

/**
 * The addOrganizationUser data action
 * Calls the read RetailServer API to get the BusinessPartnerId from the admin
 * account and adds that businessPartner to the recieved input (new User)/
 * We then send this new User through the RetailServer API so that the database
 * is updated.
 */
export default createObservableDataAction<BusinessPartnerUser>({
    id: '@msdyn365-commerce-modules/retail-actions/add-organization-user',
    action: <IAction<BusinessPartnerUser>>addOrganizationUser,
    input: <(args: ICreateActionContext) => IActionInput>(<IAny>createAddOrganizationUserInput),
});