import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { InvoicePaidStatus, InvoiceSearchCriteria, InvoiceType, OrderInvoice } from '@msdyn365-commerce/retail-proxy';
import { getInvoicesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/SalesOrdersDataActions.g';

const getDefaultSearchCriteria = (): InvoiceSearchCriteria => {
    const searchCriteria: InvoiceSearchCriteria = {};
    searchCriteria.InvoiceTypeValues = [InvoiceType.SalesOrderInvoice];
    searchCriteria.InvoicePaidStatusValues = [InvoicePaidStatus.None];
    return searchCriteria;
};

/**
 * Input class for get invoice data action
 */
export class GetInvoicesInput implements IActionInput {
    public readonly searchCriteria: InvoiceSearchCriteria;

    constructor(searchCriteria: InvoiceSearchCriteria) {
        this.searchCriteria = searchCriteria;
    }

    public getCacheKey = () => 'BusinessPartnerInvoices';
    public getCacheObjectType = () => 'OrderInvoice';
    public dataCacheType = (): CacheType => 'none';
}

export const CreateGetInvoicesInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    return new GetInvoicesInput(getDefaultSearchCriteria());
};

export async function getInvoices(input: GetInvoicesInput, context: IActionContext): Promise<OrderInvoice[]> {
    if (!context?.requestContext?.user?.isAuthenticated) {
        throw new Error('Unable to get list of invoices. User is not authenticated.');
    }

    return getInvoicesAsync({ callerContext: context }, input.searchCriteria);
}

export default createObservableDataAction({
    id: 'get-invoices',
    action: <IAction<OrderInvoice[]>>getInvoices,
    input: CreateGetInvoicesInput
});
