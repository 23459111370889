import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IStoreSelectorStateManager } from './i-store-selection-state-manager';
import { MapStoreSelectorStateManager } from './map-store-selector-state-manager';

export function createStoreSelectorStateInput(result: IStoreSelectorStateManager): GenericInput<IStoreSelectorStateManager> {
    return new GenericInput<IStoreSelectorStateManager>('mapSSSM', result, 'IStoreSelectorStateManager');
}

const createStoreSelectorStateInputInternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createStoreSelectorStateInput(new MapStoreSelectorStateManager());
};

export default createObservableDataAction({
    action: <IAction<IStoreSelectorStateManager>>getGenericAction,
    input: createStoreSelectorStateInputInternal
});
