import { ObjectExtensions } from './object-extensions';

/**
 * Namespace which extends Array functionality.
 */
export namespace ArrayExtensions {
    /**
     * @description Remove duplicate elements in the array.
     * @param {T[]} value The array to examine.
     * @returns {T[]} An array with only unique elements.
     */
    export function unique<T>(value: T[]): T[] {
        if (!hasElements(value)) {
            return [];
        }

        return Array.from(new Set(value));
    }

    /**
     * @description Verifies whether the object array has elements.
     * @param {ReadonlyArray<T>} value The array to examine.
     * @returns {boolean} True if the object has elements, false otherwise.
     */
    export function hasElements<T>(value: ReadonlyArray<T> | null | undefined): boolean {
        return !ObjectExtensions.isNullOrUndefined(value) && value.length > 0;
    }

    /**
     * @description Checks if all elements satisfy given condition.
     * @param {T[]} value The array to examine.
     * @param {(value: T, index: number, array: T[]) => boolean} predicate The function which checks an element.
     * @returns {boolean} True if the array is empty or all the inner elements satisfy given condition,
     * false if at least one element does not pass the condition.
     */
    export function all<T>(value: T[], predicate: (value: T, index: number, array: T[]) => boolean): boolean {
        if (!hasElements(value)) {
            return true;
        }

        return value.filter(predicate).length === value.length;
    }
}