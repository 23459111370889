import { CacheType, IAction, IActionContext, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { Address } from '@msdyn365-commerce/retail-proxy';

import { buildCacheKey } from './utilities/utils';
import getCustomer, { GetCustomerInput } from './get-customer';

/**
 *  Input class for the getAddress data action
 */
export class AddressInput implements IActionInput {
    public userAccountNumber?: string;
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, userAccountNumber?: string) {
        this.userAccountNumber = userAccountNumber;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`GetAddress-${this.userAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'GetAddress';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the getAddress method
 * @param inputData The input data passed to the createInput method
 */
export const createGetAddressInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create address input.  User is not authenticated.');
    }

    return new AddressInput(inputData.requestContext.apiSettings);
};

/**
 * The action method for the getAddress data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getAddressAction(input: AddressInput, ctx: IActionContext): Promise<Address[]> {
    const customerInput = new GetCustomerInput(ctx.requestContext.apiSettings, input.userAccountNumber);
    const customer = await getCustomer(customerInput, ctx);

    if (!customer) {
        throw new Error('Not able to get customer');
    }

    return customer.Addresses || [];
}

/**
 * The getAddress data action
 * Gets a customers information via the read RetailServer API
 * Returns address information associated with the retrieved customer
 */
export default createObservableDataAction<Address[]>({
    id: '@msdyn365-commerce-modules/retail-actions/get-address',
    action: <IAction<Address[]>>getAddressAction,
    input: <(args: ICreateActionContext) => IActionInput>createGetAddressInput
});